<template>
  <div>
    <app-collapse
      accordion
      type="margin"
    >
      <QuoteProductCollapsableItem
        v-for="(product, index) in products"
        :key="product.IdProduct"
        :product="product"
        :is-visible="index == 0 ? true : false"
      />
    </app-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'

export default {
  name: 'QuoteProductList',
  components: {
    AppCollapse,
    QuoteProductCollapsableItem: () => import('@/modules/trade/quotes/components/cards/QuoteProductCollapsableItem.vue'),
  },
  data() {
    return {
      products: [],
    }
  },
  computed: {
    ...mapGetters({
      getQuoteProducts: 'quotes/getQuoteProducts',
    }),
    quoteProducts: {
      get() { return this.getQuoteProducts },
    },
  },
  watch: {
    quoteProducts(products) {
      this.products = [...products]
    },
  },
  async created() {
    this.products = this.quoteProducts
  },
}
</script>
