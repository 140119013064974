<template>
  <div>
    <b-card class="border border-dark shadow-none">
      <b-card-text>
        <b-row align-h="between">
          <b-col md="4">
            <b-row>
              <b-col md="12">
                <h4>Envío e instalación</h4>
              </b-col>

              <!-- #region::Delivery type select -->
              <b-col md="12">
                <SelectWithValidation
                  v-model="selectedDeliveryType"
                  vid="deliveryType"
                  name="tipo de entrega"
                  label="Tipo de entrega"
                  property="text"
                  placeholder="Selecciona el tipo de entrega"
                  :options="getDeliveryTypes"
                />
              </b-col>
              <!-- #endregion::Delivery type select -->

              <!-- #region::Invoice required radiobuttons -->
              <b-col
                md="12"
                class="mt-1"
              >
                <b-form-group>
                  <label for="basic-password">Solicitud de factura</label>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="invoiceRequired"
                      value="Si"
                      name="type-radios"
                      class="mt-0"
                    >
                      Si
                    </b-form-radio>
                    <b-form-radio
                      v-model="invoiceRequired"
                      value="No"
                      name="type-radios"
                      class="mt-0"
                      checked
                    >
                      No
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <!-- #endregion::Invoice required radiobuttons -->

              <!-- #region::Count to be invoiced input -->
              <b-col
                v-if="invoiceRequired === 'Si'"
                md="12"
              >
                <TextInputWithValidation
                  v-model="countToInvoice"
                  vid="countToInvoice"
                  :rules="{
                    required: false,
                    negative,
                    notalpha,
                    decimal,
                    'min_value': 1,
                  }"
                  type="text"
                  name="monto a facturar"
                  label="Monto a facturar"
                  maxlength="12"
                  placeholder="100.00"
                  prepend="$"
                />
              </b-col>
              <!-- #endregion::Count to be invoiced input -->

              <!-- #region::Arrive date picker -->
              <b-col
                md="12"
                class="mt-1"
              >
                <DatePickerWithValidation
                  v-model="arriveDate"
                  vid="arriveDate"
                  rules="required"
                  label="Fecha de entrega requerida"
                  name="fecha de entrega requerida"
                  placeholder="Selecciona la fecha de entrega requerida"
                />
              </b-col>
              <!-- #endregion::Arrive date picker -->

            </b-row>
          </b-col>
          <b-col>
            <hr class="verticalhr">
          </b-col>
          <b-col md="4">
            <b-row>
              <b-col md="12">
                <h4>Total de la cotizacización</h4>
              </b-col>

              <!-- #region::Subtotal USD label -->
              <b-col
                md="12"
                class="mt-1 d-flex justify-content-between"
              >
                <h5>Subtotal USD</h5>
                <h5>{{ subtotalUSD | currency }}</h5>
              </b-col>
              <b-col md="12">
                <hr>
              </b-col>
              <!-- #endregion::Subtotal USD label -->

              <!-- #region::Exchange type label -->
              <b-col
                md="12"
                class="mt-1"
              >
                <b-row
                  align-h="between"
                >
                  <b-col
                    md="8"
                    class="align-self-center"
                  >
                    <h5>Tipo de cambio</h5>
                  </b-col>
                  <b-col md="4">
                    <TextInputWithValidation
                      v-model="exchangeType"
                      vid="exchangeType"
                      :rules="{
                        required: exchangeTypeRequired,
                        negative,
                        notalpha,
                        decimal,
                      }"
                      type="text"
                      name="tipo de cambio"
                      maxlength="12"
                      placeholder="100.00"
                      prepend="$"
                      :styled="false"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12">
                <hr>
              </b-col>
              <!-- #endregion::Exchange type label -->

              <!-- #region::Discount label -->
              <b-col
                md="12"
                class="mt-1"
              >
                <b-row
                  align-h="between"
                >
                  <b-col
                    md="8"
                    class="align-self-center"
                  >
                    <h5>Descuento</h5>
                  </b-col>
                  <b-col md="4">
                    <TextInputWithValidation
                      v-model="discount"
                      vid="discount"
                      rules="negative|notalpha|decimal|max_value:100"
                      type="text"
                      name="descuento"
                      maxlength="5"
                      placeholder="0.00"
                      append="%"
                      :styled="false"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12">
                <hr>
              </b-col>
              <!-- #endregion::Discount label -->

              <!-- #region::Subtotal MXN label -->
              <b-col
                md="12"
                class="mt-1 d-flex justify-content-between"
              >
                <h5><strong>Subtotal MXN</strong></h5>
                <h5>{{ subtotalMXN | currency }}</h5>
              </b-col>
              <b-col md="12">
                <hr>
              </b-col>
              <!-- #endregion::Subtotal MXN label -->

              <!-- #region::Count to be invoiced label -->
              <b-col
                v-if="invoiceRequired === 'Si' && countToInvoice"
                md="12"
                class="mt-1 d-flex justify-content-between"
              >
                <h5>Monto a facturar</h5>
                <h5>{{ countToInvoice | currency }}</h5>
              </b-col>
              <b-col
                v-if="invoiceRequired === 'Si' && countToInvoice"
                md="12"
              >
                <hr>
              </b-col>
              <!-- #endregion::Count to be invoiced label -->

              <!-- #region::IVA label -->
              <b-col
                md="12"
                class="mt-1 d-flex justify-content-between"
              >
                <h5>IVA</h5>
                <h5>{{ IVA | currency }}</h5>
              </b-col>
              <b-col md="12">
                <hr>
              </b-col>
              <!-- #endregion::IVA label -->

              <!-- #region::Shipment label -->
              <b-col
                md="12"
                class="mt-1"
              >
                <b-row
                  align-h="between"
                >
                  <b-col
                    md="8"
                    class="align-self-center"
                  >
                    <h5>Envío</h5>
                  </b-col>
                  <b-col md="4">
                    <TextInputWithValidation
                      v-model="shipmentCost"
                      vid="shipmentCost"
                      rules="negative|notalpha|decimal"
                      type="text"
                      name="envío"
                      maxlength="12"
                      placeholder="100.00"
                      prepend="$"
                      :styled="false"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12">
                <hr>
              </b-col>
              <!-- #endregion::Shipment label -->

              <!-- #region::Installation label -->
              <b-col
                md="12"
                class="mt-1"
              >
                <b-row
                  align-h="between"
                >
                  <b-col
                    md="8"
                    class="align-self-center"
                  >
                    <h5>Instalación</h5>
                  </b-col>
                  <b-col md="4">
                    <TextInputWithValidation
                      v-model="installationCost"
                      vid="installationCost"
                      rules="negative|notalpha|decimal"
                      type="text"
                      name="instalación"
                      maxlength="12"
                      placeholder="100.00"
                      prepend="$"
                      :styled="false"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12">
                <hr>
              </b-col>
              <!-- #endregion::Installation label -->

              <!-- #region::Total label -->
              <b-col
                md="12"
                class="mt-1 d-flex justify-content-between"
              >
                <h5><strong>Total MXN</strong></h5>
                <h5><strong>{{ totalMXN | currency }}</strong></h5>
              </b-col>
              <!-- #endregion::Total label -->

            </b-row>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  required, decimal, notalpha, minValue, negative,
} from '@validations'
import {
  BCardText, BCard, BRow, BCol, BFormGroup, BFormRadio,
} from 'bootstrap-vue'

import SelectWithValidation from '@/components/forms/SelectWithValidation.vue'
import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
import DatePickerWithValidation from '@/components/forms/DatePickerWithValidation.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormGroup,
    BFormRadio,
    SelectWithValidation,
    TextInputWithValidation,
    DatePickerWithValidation,
  },
  props: {
    quoteData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      /**
       * Validations
       */
      decimal,
      required,
      notalpha,
      minValue,
      negative,
    }
  },
  computed: {
    ...mapGetters({
      getQuoteProducts: 'quotes/getQuoteProducts',
      getQuoteCircuits: 'quotes/getQuoteCircuits',

      /**
       * Delivery types and methods
       */
      getDeliveryTypes: 'quotes/getDeliveryTypes',
      getDeliveryMethods: 'quotes/getDeliveryMethods',

      getArriveDate: 'quotes/getArriveDate',
      getCountToInvoice: 'quotes/getCountToInvoice',
      getInvoiceRequired: 'quotes/getInvoiceRequired',
      getSelectedDeliveryType: 'quotes/getSelectedDeliveryType',

      /**
       * Información del total de la cotización
       */
      getIVA: 'quotes/getIVA',
      getTotalMXN: 'quotes/getTotalMXN',
      getDiscount: 'quotes/getDiscount',
      getSubtotalUSD: 'quotes/getSubtotalUSD',
      getSubtotalMXN: 'quotes/getSubtotalMXN',
      getExchangeType: 'quotes/getExchangeType',
      getShipmentCost: 'quotes/getShipmentCost',
      getInstallationCost: 'quotes/getInstallationCost',
    }),
    /**
     * Productos de la cotización
     */
    quoteProducts: {
      get() { return this.getQuoteProducts },
    },
    /**
     * Circuitos de la cotización
     */
    quoteCircuits: {
      get() { return this.getQuoteCircuits },
    },
    /**
     * Subtotal de la cotización en USD
     */
    subtotalUSD: {
      get() { return this.getSubtotalUSD },
    },
    /**
     * Total de la cotización en MXN
     */
    totalMXN: {
      get() { return this.getTotalMXN },
    },
    /**
     * Subtotal de la cotización en MXN
     */
    subtotalMXN: {
      get() { return this.getSubtotalMXN },
    },
    /**
     * IVA de la cotización
     */
    IVA: {
      get() { return this.getIVA },
    },
    /**
     * Fecha de entrega requerida
     */
    arriveDate: {
      get() { return this.getArriveDate },
      set(value) { this.setArriveDate(value) },
    },
    /**
     * Monto a facturar
     */
    countToInvoice: {
      get() { return this.getCountToInvoice },
      set(value) { this.setCountToInvoice(value) },
    },
    /**
     * Indica si se requiere factura o no
     */
    invoiceRequired: {
      get() { return this.getInvoiceRequired },
      set(value) { this.setInvoiceRequired(value) },
    },
    /**
     * Tipo de entrega seleccionado
     */
    selectedDeliveryType: {
      get() { return this.getSelectedDeliveryType },
      set(value) { this.setSelectedDeliveryType(value) },
    },
    /**
     * Tipo de cambio
     */
    exchangeType: {
      get() { return this.getExchangeType },
      set(value) { this.setExchangeType(value) },
    },
    /**
     * Descuento
     */
    discount: {
      get() { return this.getDiscount },
      set(value) { this.setDiscount(value) },
    },
    /**
     * Costo de envío
     */
    shipmentCost: {
      get() { return this.getShipmentCost },
      set(value) { this.setShipmentCost(value) },
    },
    /**
     * Costo de instalación
     */
    installationCost: {
      get() { return this.getInstallationCost },
      set(value) { this.setInstallationCost(value) },
    },
    /**
     * Indica si se requiere tipo de cambio siempre y cuando existan
     * productos o circuitos
     */
    exchangeTypeRequired() {
      return this.quoteProducts.length > 0 || this.quoteCircuits.length > 0
    },
  },
  watch: {
    invoiceRequired(value) {
      if (value === 'No') {
        this.countToInvoice = 0
      }
    },
  },

  /**
  * Hook que se ejecuta cuando el componente es creado
  *
  * @summary Si existe información de la cotización, se carga en el formulario
  */
  created() {
    if (this.quoteData) {
      this.loadExistingExtraInformation(this.quoteData)
    }
  },
  methods: {
    ...mapActions({
      setDiscount: 'quotes/setDiscount',
      setArriveDate: 'quotes/setArriveDate',
      setExchangeType: 'quotes/setExchangeType',
      setShipmentCost: 'quotes/setShipmentCost',
      setCountToInvoice: 'quotes/setCountToInvoice',
      setInvoiceRequired: 'quotes/setInvoiceRequired',
      setInstallationCost: 'quotes/setInstallationCost',
      setSelectedDeliveryType: 'quotes/setSelectedDeliveryType',
    }),

    /**
    * Carga la información de la cotización en el formulario
    *
    * @summary Verifica que existan los campos en la cotización, si
    * existen, se cargan en el formulario
    */
    loadExistingExtraInformation(quote) {
      this.invoiceRequired = quote.Invoice
      this.countToInvoice = quote.AmountInvoiced || 0

      if (quote.Discount) {
        this.discount = quote.Discount
      }

      if (quote.ExchangeRate) {
        this.exchangeType = quote.ExchangeRate
      }

      if (quote.ShippingCost) {
        this.shipmentCost = quote.ShippingCost
      }

      if (quote.RequiredDeliveryDate) {
        this.arriveDate = quote.RequiredDeliveryDate
      }

      if (quote.InstallationCost) {
        this.installationCost = quote.InstallationCost
      }

      if (quote.DeliveryType) {
        this.selectedDeliveryType = { text: quote.DeliveryType }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
hr.verticalhr {
    border: none;
    border-left: 1px solid rgb(223, 223, 223);
    height: 65vh;
    width: 1px;
}
</style>
