<template>
  <b-card class="border border-dark shadow-none">
    <div>
      <b-row>

        <!-- #region::Title -->
        <b-col class="align-self-end">
          <h4 class="font-weight-bolder">
            Datos de facturación
          </h4>
        </b-col>
        <!-- #endregion::Title -->

        <!-- #region::Collapsable options -->
        <b-col
          cols="12"
          md="auto"
          class="ml-auto"
        >

          <!-- #region begin::Open modal button -->
          <b-button
            v-if="showUseOtherButton"
            variant="flat-primary"
            size="sm"
            :disabled="userCustomer === false"
            @click="openModal"
          >
            Usar otra
          </b-button>
          <!-- #endregion end::Open modal button -->

          <!-- #region begin::Collapse card button -->
          <b-button
            v-if="userCustomer || showToggleButton"
            :variant="visible ? 'flat-primary' : 'flat-secondary'"
            class="btn-icon rounded-circle ml-1"
            @click="collapse"
          >
            <feather-icon :icon="iconToggle" />
          </b-button>
          <!-- #endregion end::Collapse card button -->

        </b-col>
        <!-- #endregion::Collapsable options -->

      </b-row>
    </div>

    <b-collapse
      :id="collapseItemId"
      v-model="visible"
      role="tabpanel"
    >
      <div>
        <hr>
        <InformationBasicCard
          v-if="showBusinessName && customerType === 'Moral'"
          title="Razón social"
          :tags="[{ value: businessName, type: 'string' }]"
        />
        <InformationBasicCard
          title="Uso de CFDI"
          :tags="[{ value: CFDIUssage, type: 'string' }]"
        />
        <InformationBasicCard
          title="RFC"
          :tags="[{ value: RFC, type: 'string' }]"
        />
      </div>
    </b-collapse>

    <!-- #region::Form modal -->
    <b-modal
      id="invoiceDataModal"
      ref="invoiceDataModal"
      title="Datos de facturación"
      centered
      size="lg"
      @hide="resetValues"
    >
      <b-card-text>

        <b-alert
          show
          variant="info"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="AlertCircleIcon"
            />
            <span class="ml-25">La información modificada solo será parte de esta cotización. Para guardar la información del cliente haz click en el checkbox de abajo.</span>
          </div>
        </b-alert>

        <validation-observer ref="invoiceDataForm">

          <!-- #region::Form -->
          <b-form @submit.stop.prevent="handleSubmit">
            <b-row>

              <!-- #region::Business name input -->
              <b-col md="6">
                <TextInputWithValidation
                  v-model="formCFDIUssage"
                  vid="formCFDIUssage"
                  rules="required|min:2"
                  type="text"
                  label="Uso de CFDI"
                  name="uso de CFDI"
                  maxlength="200"
                  placeholder="Escribe el uso de CFDI"
                />
              </b-col>
              <!-- #endregion::Business name input -->

              <!-- #region::Business name input -->
              <b-col md="6">
                <TextInputWithValidation
                  v-model="formRFC"
                  vid="formRFC"
                  rules="required|min:2"
                  type="text"
                  label="RFC"
                  name="RFC"
                  maxlength="200"
                  placeholder="Escribe el RFC"
                />
              </b-col>
              <!-- #endregion::Business name input -->

              <!-- #region::Update data checkbox -->
              <b-col md="12">
                <b-form-checkbox
                  v-model="updateData"
                  class="mt-1"
                >
                  Actualizar información del cliente
                </b-form-checkbox>
              </b-col>
              <!-- #endregion::Update data checkbox -->

            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>

      <!-- #region::Footer -->
      <template #modal-footer>
        <b-button
          variant="delete-btn"
          class="delete-btn"
          @click="hideModal"
        >
          Cancelar
        </b-button>
        <b-button
          variant="principal-btn"
          class="principal-btn"
          @click="handleReplaceData"
        >
          Usar estos datos
        </b-button>
      </template>
      <!-- #endregion::Footer -->

    </b-modal>
    <!-- #endregion::Form modal -->

  </b-card>
</template>

<script>
// #region Imports
import { mapActions, mapGetters } from 'vuex'
import { ValidationObserver, localize } from 'vee-validate'
import { required, min } from '@validations'
import {
  BCol, BButton, BCard, BRow, BCollapse, BModal, VBModal, BCardText, BForm, BAlert, BFormCheckbox,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

import InformationBasicCard from '@/components/cards/InformationBasicCard.vue'
import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BCard,
    BAlert,
    BModal,
    BButton,
    BCardText,
    BCollapse,
    BFormCheckbox,
    ValidationObserver,
    InformationBasicCard,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    TextInputWithValidation,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    quote: {
      type: Object,
      default: null,
    },
    customerData: {
      type: Object,
      default: null,
    },
    showToggleButton: {
      type: Boolean,
      default: false,
    },
    showUseOtherButton: {
      type: Boolean,
      default: true,
    },
    showBusinessName: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemId: '1',
      iconToggle: 'ChevronDownIcon',

      updateData: false,

      formRFC: '',
      formCFDIUssage: '',
      // formBusinessName: '',

      // * 31/10/2022 - TAG: Validations
      min,
      required,
    }
  },
  computed: {
    ...mapGetters({
      getRFC: 'quotes/getRFC',
      getIdQuote: 'quotes/getIdQuote',
      getCFDIUssage: 'quotes/getCFDIUssage',
      getBusinessName: 'quotes/getBusinessName',
      getCustomerType: 'quotes/getCustomerType',
      getSelectedCustomer: 'quotes/getSelectedCustomer',
    }),
    RFC: {
      get() { return this.getRFC },
      set(value) { this.setRFC(value) },
    },
    idQuote: {
      get() { return this.getIdQuote },
      set(value) { this.setIdQuote(value) },
    },
    CFDIUssage: {
      get() { return this.getCFDIUssage },
      set(value) { this.setCFDIUssage(value) },
    },
    businessName: {
      get() { return this.getBusinessName },
      // set(value) { this.setBusinessName(value) },
    },
    customerType: {
      get() { return this.getCustomerType },
    },
    selectedCustomer: {
      get() { return this.getSelectedCustomer },
    },
    userCustomer() {
      if (this.selectedCustomer) {
        const { user } = this.selectedCustomer

        if (user.length > 0) {
          const [userCustomer] = user
          return userCustomer
        }

        return false
      }

      if (this.customerData) {
        return this.customerData
      }

      return false
    },
  },
  watch: {
    selectedCustomer() {
      if (this.userCustomer) {
        this.loadExistingInvoiceData(this.userCustomer)
      } else if (this.visible) {
        this.collapse()
      }
    },
  },
  created() {
    if (this.quote) {
      const [quoteInfo] = this.quote.quote
      const [customerInfo] = this.quote.client
      this.loadExistingInvoiceData({
        Rfc: quoteInfo.Rfc || customerInfo.Rfc,
        Cfdi: quoteInfo.Cfdi || customerInfo.Cfdi,
      })
    }
    // else if (this.customerData) {
    //   this.loadExistingInvoiceData(this.customerData)
    // }

    localize('es')
    this.collapseItemId = uuidv4()
  },
  methods: {
    ...mapActions({
      setRFC: 'quotes/setRFC',
      setIdQuote: 'quotes/setIdQuote',
      setCFDIUssage: 'quotes/setCFDIUssage',
      // setBusinessName: 'quotes/setBusinessName',
      updateCustomerInvoiceData: 'quotes/updateCustomerInvoiceData',
    }),
    collapse() {
      this.$root.$emit('bv::toggle::collapse', this.collapseItemId)
      this.visible = !this.visible
      this.setToggleIcon()
    },
    setToggleIcon() {
      this.iconToggle = this.visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },
    openModal() {
      this.$refs.invoiceDataModal.show()
    },
    handleReplaceData(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      const success = await this.$refs.invoiceDataForm.validate()

      if (success) {
        this.RFC = this.formRFC
        this.CFDIUssage = this.formCFDIUssage
        // this.businessName = this.formBusinessName

        const formData = new FormData()

        formData.append('id', this.userCustomer.IdUser)
        formData.append('saveInfo', this.updateData ? 1 : 0)

        if (this.idQuote) {
          formData.append('idQuote', this.idQuote)
        }

        formData.append('rfc', this.RFC)
        formData.append('cfdi', this.CFDIUssage)
        // formData.append('businessName', this.businessName)

        // eslint-disable-next-line no-restricted-syntax
        for (const pair of formData.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`)
        }

        try {
          const response = await this.updateCustomerInvoiceData(formData)

          if (!this.idQuote) {
            this.idQuote = response.data.data
          }

          this.showSwalFire('¡Los datos del cliente han sido actualizados correctamente!')
        } catch (error) {
          this.$swal.close()
          this.showToast('Error de validación', getError(error), 'danger')
        }

        this.$nextTick(() => {
          this.$refs.invoiceDataModal.toggle('#toggle-btn')
        })

        this.resetValues()
      }
    },
    hideModal() {
      this.$refs.invoiceDataModal.hide()
      this.resetValues()
    },
    resetValues() {
      this.formRFC = ''
      this.formCFDIUssage = ''
      // this.formBusinessName = ''
      this.updateData = false
    },
    loadExistingInvoiceData(customer) {
      this.RFC = customer.Rfc || 'Sin especificar'
      this.CFDIUssage = customer.Cfdi || 'Sin especificar'
      // this.businessName = customer.BusinessName || 'Sin especificar'
    },
    showSwalFire(message) {
      this.$swal.fire({
        title: 'Guardado',
        text: message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        allowOutsideClick: false,
      })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
