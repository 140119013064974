<template>

  <!-- #region::Add products to quote modal -->
  <b-modal
    id="products-modal"
    ref="products-modal"
    title="Agregar productos a la cotización"
    centered
    size="xl"
    @hidden="onCloseModal"
  >
    <b-card-text>
      <b-row>
        <b-col
          cols="12"
          class="mb-1"
        >
          <h3>Productos</h3>
        </b-col>

        <b-col
          cols="12"
        >
          <b-alert
            show
            variant="info"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="AlertCircleIcon"
              />
              <span class="ml-25">Las cotizaciones deben tener productos y piezas de la misma ubicación.</span>
            </div>
          </b-alert>
        </b-col>

        <!-- #region begin::Products list -->
        <b-col>
          <ProductsList
            resource="products"
            :show-pieces-list="true"
            :show-location-filter="true"
            :selectable-pieces-list="true"
            :can-apply-global-filters="false"
            :can-apply-global-pagination="false"
            @hide-modal="hideModal"
          />
        </b-col>
        <!-- #endregion end::Products list -->
      </b-row>
    </b-card-text>

    <!-- #region::Footer -->
    <template #modal-footer>
      <b-button
        variant="delete-btn"
        class="delete-btn"
        @click="hideModal"
      >
        Cancelar
      </b-button>
      <b-button
        variant="principal-btn"
        class="principal-btn"
        @click="onAddPieces"
      >
        {{ addButtonText }}
      </b-button>
    </template>
    <!-- #endregion::Footer -->

  </b-modal>
  <!-- #endregion::Add products to quote modal -->

</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BRow, BCol, BModal, VBModal, BCardText, BButton, BAlert,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BModal,
    BButton,
    BCardText,
    ProductsList: () => import('@/modules/production/products/components/ProductsList.vue'),
  },
  directives: {
    'b-modal': VBModal,
  },
  computed: {
    ...mapGetters({
      getQuotePieces: 'quotes/getQuotePieces',
      getQuoteProducts: 'quotes/getQuoteProducts',
      getQuoteCircuits: 'quotes/getQuoteCircuits',
      getProvitionalPieces: 'circuits/getProvitionalPieces',
      getProvitionalProducts: 'circuits/getProvitionalProducts',
    }),
    quoteProducts: {
      get() { return this.getQuoteProducts },
    },
    quoteCircuits: {
      get() { return this.getQuoteCircuits },
    },
    quotePieces: {
      get() { return this.getQuotePieces },
    },
    provitionalPieces: {
      get() { return this.getProvitionalPieces },
    },
    provitionalProducts: {
      get() { return this.getProvitionalProducts },
    },
    addButtonText() {
      let text = 'Añadir a cotización'

      if (this.provitionalPieces.length > 0) {
        text += ` (${this.provitionalPieces.length})`
      }

      return text
    },
  },
  methods: {
    ...mapActions({
      clearFormData: 'circuits/clearFormData',
      addQuoteProduct: 'quotes/addQuoteProduct',
      addProvitionalProduct: 'circuits/addProvitionalProduct',
    }),

    /**
    * Muestra el modal para agregar productos a la cotización
    *
    * @summary Revisa los productos de la cotización y los agrega la lista de productos provicionales
    * que sirve para mantener los productos seleccionados en el modal
    */
    showModal() {
      this.quoteProducts.forEach(quoteProduct => {
        const provitionalProduct = { ...quoteProduct }
        provitionalProduct.pieces = quoteProduct.pieces.map(piece => ({ ...piece }))

        this.addProvitionalProduct(provitionalProduct)
      })

      this.$refs['products-modal'].show()
    },

    /**
     * Oculta el modal para agregar productos a la cotización
     */
    hideModal() {
      this.$refs['products-modal'].hide()
    },
    onAddPieces() {
      if (this.provitionalPieces.length > 0) {
        const isSameLocation = piece => piece.Location === this.provitionalPieces[0].Location
        const isAvailable = piece => piece.Availability !== 'No disponible'
        const isInTransfer = piece => piece.Transfer === 'Si'

        const sameLocation = this.provitionalPieces.every(isSameLocation)
        const available = this.provitionalPieces.every(isAvailable)
        const inTransfer = this.provitionalPieces.some(isInTransfer)

        if (!available) {
          this.showToast(
            'Error de validación',
            'Una o más piezas seleccionadas no están disponibles.',
            'warning',
          )
          return
        }

        if (!sameLocation) {
          this.showToast(
            'Error de validación',
            'Las piezas seleccionadas no pertenecen a la misma ubicación.',
            'warning',
          )
          return
        }

        if (inTransfer) {
          this.showToast(
            'Error de validación',
            'Una o más piezas seleccionadas se encuentran en una orden de traspaso.',
            'warning',
          )
          return
        }

        const isPriceDefined = provitionalProduct => provitionalProduct.PricesDefined === 0
        const priceDefined = this.provitionalProducts.every(isPriceDefined)

        if (!priceDefined) {
          this.showToast(
            'Error de validación',
            'Una o más piezas seleccionadas pertenecen a un producto con precios por definir.',
            'warning',
          )
          return
        }

        if (this.quotePieces.length > 0) {
          const isSameLocationQuote = piece => piece.Location === this.quotePieces[0].Location
          const sameLocationQuote = this.provitionalPieces.every(isSameLocationQuote)

          if (!sameLocationQuote) {
            this.showToast(
              'Error de validación',
              'Existen piezas con diferente ubicación agregadas en la cotización.',
              'warning',
            )
            return
          }
        }

        if (this.quoteCircuits.length > 0) {
          const isSameLocationCircuit = piece => piece.Location === this.quoteCircuits[0].Location
          const sameLocationCircuit = this.provitionalPieces.every(isSameLocationCircuit)

          if (!sameLocationCircuit) {
            this.showToast(
              'Error de validación',
              'Existen circuitos con diferente ubicación agregados en la cotización.',
              'warning',
            )
            return
          }
        }

        this.provitionalProducts.forEach(provitionalProduct => {
          const quoteProduct = { ...provitionalProduct }
          quoteProduct.pieces = provitionalProduct.pieces.map(piece => ({ ...piece }))

          const quoteProductAdded = this.quoteProducts.find(p => p.IdProduct === quoteProduct.IdProduct)

          if (quoteProductAdded) {
            this.$set(quoteProduct, 'unitPrice', quoteProductAdded.unitPrice)
            this.$set(quoteProduct, 'unitPriceType', quoteProductAdded.unitPriceType)
            this.$set(quoteProduct, 'unitPriceOrigin', quoteProductAdded.unitPriceOrigin)
          } else {
            this.$set(quoteProduct, 'unitPrice', 0)
            this.$set(quoteProduct, 'unitPriceType', '')
            this.$set(quoteProduct, 'unitPriceOrigin', 0)
          }

          this.addQuoteProduct(quoteProduct)
          this.hideModal()
        })
      } else {
        this.showToast(
          'Error de validación',
          'No hay piezas seleccionadas para agregar a la cotización.',
          'warning',
        )
      }
    },
    onCloseModal() {
      this.clearFormData()
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
