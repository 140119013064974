<template>
  <b-card
    class="mt-1 border border-dark shadow-none"
    no-body
  >
    <b-card-text>

      <!-- #region::Circuits list -->
      <b-table
        :items="formartedItems"
        :fields="columns"
        responsive="sm"
        class="my-0"
      >

        <!-- #region::A virtual column for circuit id -->
        <template #cell(IdCircuit)="{ item }">
          <div class="d-flex">
            <span class="text-nowrap">{{ item.IdCircuit }}</span>
            <div
              v-if="item.Transfer === 'Si'"
              class="sm-banner-alert alert-marine"
              role="alert"
            >
              Traspaso
            </div>
          </div>
        </template>
        <!-- #endregion::A virtual column for circuit id -->

        <!-- #region::A virtual column for prices -->
        <template #cell(prices)="{ item }">
          {{ item.PriceMin | currency }} - {{ item.PriceMax | currency }}
        </template>
        <!-- #endregion::A virtual column for prices -->

        <!-- #region::A virtual column for offer price -->
        <template #cell(OfferPrice)="{ item }">
          <div
            v-if="item.OfferPrice"
            class="d-flex"
          >
            <b-form-checkbox
              v-model="item.ActiveOfferPrice"
              style="margin-left: -25px !important;"
              @change="onOfferPriceSelected(item)"
            />
            <span class="text-nowrap">{{ item.OfferPrice | currency }}</span>
          </div>
        </template>
        <!-- #endregion::A virtual column for offer price -->

        <!-- #region::A virtual column for total -->
        <template #cell(total)="{ item }">
          {{ totalUnitPrices(item) | currency }}
        </template>
        <!-- #endregion::A virtual column for total -->

        <!-- #region::A virtual column for actions -->
        <template #cell(actions)="{ item }">

          <!-- #region::Delete circuit button -->
          <b-button
            v-b-tooltip.hover.top="'Eliminar'"
            variant="flat-secondary"
            class="btn-icon rounded-circle"
            @click="onDeleteCicuit(item)"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
          <!-- #endregion::Delete circuit button -->

        </template>
        <!-- #endregion::A virtual column for actions -->

        <!-- #region::A virtual column for toggle button -->
        <template
          v-if="$ability.can('read', 'Circuit')"
          #cell(details)="{ detailsShowing, item }"
        >
          <b-button
            variant="flat-secondary"
            class="btn-icon rounded-circle"
            @click="toggleDetails(item)"
          >
            <feather-icon :icon="detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
          </b-button>
        </template>
        <!-- #endregion::A virtual column for toggle button -->

        <!-- #region::Circuit details -->
        <template
          v-if="$ability.can('read', 'Circuit')"
          v-slot:row-details="{ item }"
        >
          <b-card
            no-body
            class="mt-1"
          >
            <QuoteProductCollapsableItem
              v-for="(product, index) in item.products"
              :key="product.IdProduct"
              :product="product"
              :show-edit-button="false"
              :show-delete-button="false"
              :is-visible="index == 0 ? true : false"
              :show-pieces-list-actions-buttons="false"
            />
          </b-card>
        </template>
        <!-- #endregion::Circuit details -->

      </b-table>
      <!-- #endregion::Circuits list -->

    </b-card-text>
  </b-card>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  VBTooltip, BTable, BButton, BCard, BCardText, BFormCheckbox,
} from 'bootstrap-vue'

import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// #endregion

export default {
  name: 'CircuitsList',
  components: {
    BCard,
    BTable,
    BButton,
    BCardText,
    BFormCheckbox,
    QuoteProductCollapsableItem: () => import('@/modules/trade/quotes/components/cards/QuoteProductCollapsableItem.vue'),

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    // * Filter props
    showDownloadFile: {
      type: Boolean,
      default: false,
    },
    // * Table props
    showToggleButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      columns: [
        {
          key: 'IdCircuit',
          label: 'No. Circuito',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Name',
          label: 'Nombre',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'prices',
          label: 'Precio',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'OfferPrice',
          label: 'Precio oferta',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'total',
          label: 'Total',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Qty',
          label: 'No. de productos',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Location',
          label: 'Ubicación',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'actions',
          label: 'Acciones',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'details',
          label: '',
          thClass: this.$ability.can('read', 'Circuit') ? 'bg-light' : 'd-none',
          tdClass: this.showToggleButton && this.$ability.can('read', 'Circuit') ? '' : 'd-none',
        },
      ],
      selected: [],
      allOpenRows: [],
      currentItems: [],
      isLoadingCircuits: false,
    }
  },
  computed: {
    ...mapGetters({
      getQuoteCircuits: 'quotes/getQuoteCircuits',
    }),
    circuits: {
      get() { return this.getQuoteCircuits },
    },
    formartedItems() {
      if (!this.circuits) return []
      return this.circuits.map(item => item)
    },
  },
  methods: {
    ...mapActions({
      updateQuoteCircuit: 'quotes/updateQuoteCircuit',
      deleteQuoteCircuit: 'quotes/deleteQuoteCircuit',
      addCircuitWithOfferPriceSelected: 'quotes/addCircuitWithOfferPriceSelected',
      removeCircuitWithOfferPriceSelected: 'quotes/removeCircuitWithOfferPriceSelected',
    }),
    onRowSelected(items) {
      this.selected = items
    },
    toggleDetails(row) {
      // eslint-disable-next-line no-underscore-dangle
      if (row._showDetails) {
        this.offRow(row)
      } else {
        for (let index = 0; index < this.currentItems.length; index += 1) {
          this.offRow(this.$set(this.currentItems[index]))
        }

        this.currentItems.forEach(item => {
          this.offRow(item)
        })

        this.$nextTick(() => {
          this.onRow(row)
        })
      }
    },
    offRow(row) {
      this.$set(row, '_showDetails', false)
      this.$set(row, '_cellVariants', {
        total: '', IdCircuit: '', Name: '', prices: '', OfferPrice: '', Qty: '', Location: '', actions: '', details: '',
      })
    },
    onRow(row) {
      this.$set(row, '_showDetails', true)
      this.$set(row, '_cellVariants', {
        total: 'primary', IdCircuit: 'primary', Name: 'primary', prices: 'primary', OfferPrice: 'primary', Qty: 'primary', Location: 'primary', actions: 'primary', details: 'primary',
      })
    },

    /**
    * Precio total del circuito
    *
    * @summary Calcula el precio total del circuito a partir de los productos y piezas disponibles
    * @param {Object} circuit - Elemento que representa un circuito de la lista
    * @return {Integer} Total de los productos y piezas del circuito
    */
    totalUnitPrices(circuit) {
      let total = 0

      if (circuit.OfferPrice && circuit.ActiveOfferPrice) {
        return circuit.OfferPrice
      }

      circuit.products.forEach(product => {
        const piecesCount = product.pieces.filter(
          piece => piece.Status === 1,
        ).length

        total += product.unitPrice * piecesCount
      })

      const baseCircuit = circuit
      baseCircuit.total = total

      return total
    },

    /**
    * Evento de eliminación de circuito
    *
    * @summary Atiende la eliminación de un circuito de la lista de la cotización
    * en el store
    * @param {Object} circuit - Elemento que representa un circuito de la lista
    */
    onDeleteCicuit(circuit) {
      const quoteCircuits = this.circuits.filter(c => c.IdCircuit === circuit.IdCircuit)
      const circuitsCount = quoteCircuits.length

      quoteCircuits.forEach(quoteCircuit => {
        const updatedCircuit = quoteCircuit
        updatedCircuit.count = circuitsCount - 1
        this.updateQuoteCircuit(updatedCircuit)
      })

      this.deleteQuoteCircuit(circuit)
    },

    /**
    * Evento de selección de precio oferta
    *
    * @summary Agrega o elimina el circuito de la lista de circuitos con precio oferta seleccionado
    * @param {Object} circuit - Elemento que representa un circuito de la lista
    */
    onOfferPriceSelected(circuit) {
      if (circuit.ActiveOfferPrice) {
        circuit.products.forEach(product => {
          this.$set(product, 'unitPrice', '')
        })
        this.addCircuitWithOfferPriceSelected(circuit)
      } else {
        this.removeCircuitWithOfferPriceSelected(circuit)
      }
    },

    /**
    * Mensaje de alerta
    *
    * @summary Muestra un mensaje de alerta personalizado
    * @param {string} title - Título del mensaje
    * @param {string} text - Texto del mensaje
    * @param {string} variant - Tipo de mensaje
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
