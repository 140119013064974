<template>
  <div>

    <!-- #region begin:: List options -->
    <b-form-row>

      <!-- #region begin::Searcher -->
      <b-col md="4">
        <h3>Circuitos</h3>
      </b-col>
      <!-- #endregion end::Searcher -->

      <!-- #region begin::Add circuit to quote button -->
      <b-col
        cols="12"
        md="auto"
        class="ml-auto"
      ><b-button
        variant="principal-btn"
        class="my-1 my-lg-0 w-100 principal-btn"
        @click="$refs.circuitsModal.showModal()"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">Agregar circuito</span>
      </b-button></b-col>
      <!-- #endregion end::Add circuit to quote button -->

    </b-form-row>
    <!-- #endregion end:: List options -->

    <b-card
      v-if="quoteCircuits.length === 0"
      class="mt-1 border border-dark shadow-none text-center"
    >
      <b-card-body class="py-0">
        <p>
          <feather-icon
            icon="LayersIcon"
            class="feather-48"
          />
        </p>
        <span>Aquí se mostrarán los circuitos</span>
      </b-card-body>
    </b-card>

    <QuoteCircuitList v-else />

    <CircuitsModal
      ref="circuitsModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BButton, BCol, BFormRow, BCard, BCardBody,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

import CircuitsModal from '@/modules/trade/quotes/components/modals/CircuitsModal.vue'
import QuoteCircuitList from '@/modules/trade/quotes/components/lists/QuoteCircuitList.vue'
import buildProduct from '@/helpers/CircuitProductConverter'

export default {
  components: {
    BCol,
    BCard,
    BButton,
    BFormRow,
    BCardBody,
    CircuitsModal,
    QuoteCircuitList,
  },
  props: {
    circuitsData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      getQuoteCircuits: 'quotes/getQuoteCircuits',
    }),
    quoteCircuits: {
      get() { return this.getQuoteCircuits },
    },
  },
  created() {
    if (this.circuitsData) {
      this.circuitsData.forEach(circuit => {
        const quoteCircuit = { ...circuit }

        const quoteCircuitsProducts = []

        const uuidCircuit = uuidv4()

        circuit.products.forEach(product => {
          const formProduct = buildProduct(product)

          this.$set(formProduct, 'unitPrice', product.product.UnitPrice)
          this.$set(formProduct, 'unitPriceType', product.product.UnitPriceType)
          this.$set(formProduct, 'unitPriceOrigin', product.product.UnitPriceOrigin)
          this.$set(formProduct, 'uuidCircuit', uuidCircuit)

          quoteCircuitsProducts.push({ ...formProduct })
        })

        quoteCircuit.products = quoteCircuitsProducts
        this.$set(quoteCircuit, 'uuid', uuidCircuit)
        this.$set(quoteCircuit, 'total', 0)
        this.addQuoteCircuit(quoteCircuit)

        if (quoteCircuit.ActiveOfferPrice) {
          this.addCircuitWithOfferPriceSelected(quoteCircuit)
        }
      })
    }
  },
  methods: {
    ...mapActions({
      addQuoteCircuit: 'quotes/addQuoteCircuit',
      addCircuitWithOfferPriceSelected: 'quotes/addCircuitWithOfferPriceSelected',
    }),
  },
}
</script>

<style lang="scss" scoped>
  .feather-48{
    width: 48px;
    height: 48px;
  }
</style>
