<template>
  <div>

    <!-- #region begin:: List options -->
    <b-form-row>

      <!-- #region begin::Searcher -->
      <b-col md="4">
        <h3>Productos</h3>
      </b-col>
      <!-- #endregion end::Searcher -->

      <!-- #region begin::Add product to quote button -->
      <b-col
        cols="12"
        md="auto"
        class="ml-auto"
      ><b-button
        variant="principal-btn"
        class="my-1 my-lg-0 w-100 principal-btn"
        @click="$refs.productsModal.showModal()"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">Agregar producto</span>
      </b-button></b-col>
      <!-- #endregion end::Add product to quote button -->

    </b-form-row>
    <!-- #endregion end:: List options -->

    <!-- #region::Empty list content -->
    <b-card
      v-if="quoteProducts.length === 0"
      class="mt-1 border border-dark shadow-none text-center"
    >
      <b-card-body class="py-0">
        <p>
          <feather-icon
            icon="BoxIcon"
            class="feather-48"
          />
        </p>
        <span>Aquí se mostrarán los productos</span>
      </b-card-body>
    </b-card>
    <!-- #endregion::Empty list content -->

    <QuoteProductList v-else />

    <ProductsModal
      ref="productsModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BButton, BCol, BFormRow, BCard, BCardBody,
} from 'bootstrap-vue'

import ProductsModal from '@/modules/trade/quotes/components/modals/ProductsModal.vue'
import QuoteProductList from '@/modules/trade/quotes/components/lists/QuoteProductsList.vue'

export default {
  components: {
    BCol,
    BCard,
    BButton,
    BFormRow,
    BCardBody,
    ProductsModal,
    QuoteProductList,
  },
  props: {
    productsData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      getQuoteProducts: 'quotes/getQuoteProducts',
    }),
    quoteProducts: {
      get() { return this.getQuoteProducts },
    },
  },
  created() {
    if (this.productsData) {
      this.productsData.forEach(product => {
        const quoteProduct = { ...product.infoProduct }
        quoteProduct.pieces = product.pieces.map(piece => ({ ...piece }))

        this.$set(quoteProduct, 'unitPrice', product.product.UnitPrice)
        this.$set(quoteProduct, 'unitPriceType', product.product.UnitPriceType)
        this.$set(quoteProduct, 'unitPriceOrigin', product.product.UnitPriceOrigin)
        this.$set(quoteProduct, 'images', product.images)
        this.$set(quoteProduct, 'DeliveredMethod', product.product.MethodDelivery)

        this.addQuoteProduct(quoteProduct)
      })
    }
  },
  methods: {
    ...mapActions({
      addQuoteProduct: 'quotes/addQuoteProduct',
    }),
  },
}
</script>

<style lang="scss" scoped>
  .feather-48{
    width: 48px;
    height: 48px;
  }
</style>
