<template>
  <div>

    <b-card class="border border-dark shadow-none">
      <b-card-text>
        <b-row>
          <b-col md="12">
            <div class="d-flex justify-content-between">
              <h4>Información del cliente</h4>
              <b-button
                variant="flat-primary"
                :disabled="userCostumer === false && idCustomer === null"
                @click="openModal"
              >
                Editar datos
              </b-button>
            </div>
          </b-col>

          <!-- #region begin::Customer select -->
          <b-col md="4">
            <SelectWithValidation
              v-model="formSelectedCustomer"
              vid="formSelectedCustomer"
              rules="required"
              name="cliente"
              label="Cliente"
              property="name"
              placeholder="Selecciona el cliente"
              :clearable="false"
              :options="customCustomers"
            />

            <!-- #region begin::Create new customer button -->
            <b-button
              variant="flat-primary"
              size="sm"
              :to="{ name: 'new-customer' }"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Nuevo cliente</span>
            </b-button>
            <!-- #endregion end::Create new customer button -->

          </b-col>
          <!-- #endregion end::Customer select -->

          <!-- #region::Email -->
          <b-col
            md="4"
            class="align-self-center text-center"
          >
            <div class="d-flex justify-content-center">
              <feather-icon
                icon="MailIcon"
                class="mr-50"
                size="20"
              />
              <h4>
                Correo electrónico
              </h4>
            </div>
            <h6 :class="{'text-muted': !customerEmail}">
              {{ customerEmail | placeholder('correo@ejemplo.com') }}
            </h6>
          </b-col>
          <!-- #endregion::Email -->

          <!-- #region::Phone -->
          <b-col
            md="4"
            class="align-self-center text-center"
          >
            <div class="d-flex justify-content-center">
              <feather-icon
                icon="PhoneIcon"
                class="mr-50"
                size="20"
              />
              <h4>
                Teléfono
              </h4>
            </div>
            <h6 :class="{'text-muted': !customerPhone}">
              {{ customerPhone | placeholder('333-333-3331') }}</h6>
          </b-col>
          <!-- #endregion::Phone -->

        </b-row>
      </b-card-text>
    </b-card>

    <!-- #region::Form modal -->
    <b-modal
      id="customerDataModal"
      ref="customerDataModal"
      title="Datos del cliente"
      centered
      size="lg"
      @hide="resetValues"
    >

      <b-card-text>

        <!-- #region::Form fields -->
        <validation-observer ref="customerDataForm">

          <!-- #region::Form -->
          <b-form @submit.stop.prevent="handleSubmit">
            <b-row>

              <!-- #region::Customer type radiobuttons -->
              <b-col
                md="6"
                class="mt-1"
              >
                <b-form-group>
                  <label for="basic-password">Tipo de cliente</label>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="formCustomerType"
                      value="Físico"
                      name="type-radios"
                      class="mt-0"
                    >
                      Físico
                    </b-form-radio>
                    <b-form-radio
                      v-model="formCustomerType"
                      value="Moral"
                      name="type-radios"
                      class="mt-0"
                    >
                      Moral
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <!-- #endregion::Customer type radiobuttons -->

              <!-- #region::First name input -->
              <b-col md="3">
                <TextInputWithValidation
                  v-model="formCustomerFirstName"
                  vid="formCustomerFirstName"
                  rules="required|min:2"
                  type="text"
                  label="Nombre"
                  name="nombre"
                  maxlength="200"
                  placeholder="Escribe el nombre"
                />
              </b-col>
              <!-- #endregion::First name input -->

              <!-- #region::Last name input -->
              <b-col md="3">
                <TextInputWithValidation
                  v-model="formCustomerLastName"
                  vid="formCustomerLastName"
                  rules="required|min:2"
                  type="text"
                  label="Apellido"
                  name="apellido"
                  maxlength="200"
                  placeholder="Escribe el apellido"
                />
              </b-col>
              <!-- #endregion::Last name input -->

              <!-- #region::Phone input -->
              <b-col md="6">
                <TextInputWithValidation
                  v-model="formCustomerPhone"
                  vid="formCustomerPhone"
                  rules="required|digits:10"
                  type="text"
                  label="Teléfono"
                  name="teléfono"
                  maxlength="10"
                  placeholder="Escribe el número telefónico"
                />
              </b-col>
              <!-- #endregion::Phone input -->

              <!-- #region::Email input -->
              <b-col md="6">
                <TextInputWithValidation
                  v-model="formCustomerEmail"
                  vid="formCustomerEmail"
                  rules="required|email"
                  type="email"
                  label="Correo electrónico"
                  name="correo electrónico"
                  placeholder="Escribe el correo electrónico"
                />
              </b-col>
              <!-- #endregion::Email input -->

              <!-- #region::Business Name input -->
              <b-col
                v-if="formCustomerType == 'Moral'"
                md="6"
              >
                <TextInputWithValidation
                  v-model="formCustomerBusinessName"
                  vid="formCustomerBusinessName"
                  rules="required|min:2"
                  type="text"
                  label="Razón social"
                  name="razón social"
                  placeholder="Indica la razón social"
                />
              </b-col>
              <!-- #endregion::Business Name input -->

            </b-row>
          </b-form>
          <!-- #endregion::Form -->

        </validation-observer>
        <!-- #endregion::Form fields -->

      </b-card-text>

      <!-- #region::Footer -->
      <template #modal-footer>
        <b-button
          variant="delete-btn"
          class="delete-btn"
          @click="hideModal"
        >
          Cancelar
        </b-button>
        <b-button
          variant="principal-btn"
          class="principal-btn"
          @click="handleReplaceData"
        >
          Guardar
        </b-button>
      </template>
      <!-- #endregion::Footer -->

    </b-modal>
    <!-- #endregion::Form modal -->

  </div>
</template>

<script>
// #region Imports
import { mapActions, mapGetters, mapState } from 'vuex'
import { ValidationObserver, localize } from 'vee-validate'
import {
  required, min, email as emailRule, digits,
} from '@validations'
import {
  BCol, BButton, BCard, BCardText, BRow, BFormRadio, BModal, VBModal, BForm,
  BFormGroup,
} from 'bootstrap-vue'

import SelectWithValidation from '@/components/forms/SelectWithValidation.vue'
import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BForm,
    BModal,
    BButton,
    BCardText,
    BFormGroup,
    BFormRadio,
    ValidationObserver,
    SelectWithValidation,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    TextInputWithValidation,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    customerData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formCustomerType: 'Físico',
      formCustomerBusinessName: '',
      formCustomerPhone: '',
      formCustomerEmail: '',
      formCustomerLastName: '',
      formSelectedCustomer: '',
      formCustomerFirstName: '',

      // updateData: false,

      // * 31/10/2022 - TAG: Validations
      min,
      digits,
      required,
      emailRule,
    }
  },
  computed: {
    ...mapState({
      isLoadingCustomers: state => state.quotes.isLoadingCustomers,
    }),
    ...mapGetters({
      getIdQuote: 'quotes/getIdQuote',
      getCustomers: 'quotes/getCustomers',
      getIdCustomer: 'quotes/getIdCustomer',
      getCustomerType: 'quotes/getCustomerType',
      getBusinessName: 'quotes/getBusinessName',
      getCustomerPhone: 'quotes/getCustomerPhone',
      getCustomerEmail: 'quotes/getCustomerEmail',
      getSelectedCustomer: 'quotes/getSelectedCustomer',
      getCustomerLastName: 'quotes/getCustomerLastName',
      getCustomerFirstName: 'quotes/getCustomerFirstName',
    }),
    customers: {
      get() { return this.getCustomers },
    },
    idQuote: {
      get() { return this.getIdQuote },
      set(value) { this.setIdQuote(value) },
    },
    idCustomer: {
      get() { return this.getIdCustomer },
      set(value) { this.setIdCustomer(value) },
    },
    customerType: {
      get() { return this.getCustomerType },
      set(value) { this.setCustomerType(value) },
    },
    customerPhone: {
      get() { return this.getCustomerPhone },
      set(value) { this.setCustomerPhone(value) },
    },
    customerEmail: {
      get() { return this.getCustomerEmail },
      set(value) { this.setCustomerEmail(value) },
    },
    customerBusinessName: {
      get() { return this.getBusinessName },
      set(value) { this.setBusinessName(value) },
    },
    customerLastName: {
      get() { return this.getCustomerLastName },
      set(value) { this.setCustomerLastName(value) },
    },
    customerFirstName: {
      get() { return this.getCustomerFirstName },
      set(value) { this.setCustomerFirstName(value) },
    },
    selectedCustomer: {
      get() { return this.getSelectedCustomer },
      set(value) { this.setSelectedCustomer(value) },
    },
    customCustomers() {
      const formatedCustomers = []
      this.customers.forEach(customer => {
        const newCostumer = { ...customer }
        newCostumer.name = `${customer.name} ${customer.LastName}`
        formatedCustomers.push(newCostumer)
      })

      return formatedCustomers
    },
    userCostumer() {
      if (this.selectedCustomer) {
        const { user } = this.selectedCustomer

        if (user.length > 0) {
          const [userCostumer] = user
          return userCostumer
        }

        return false
      }

      return false
    },
  },
  watch: {
    async formSelectedCustomer(customer) {
      if (customer) {
        try {
          if (customer.IdUser) {
            const response = await this.loadCustomer(customer.IdUser)

            const { user } = response.data.data
            const [userCostumer] = user

            this.idCustomer = userCostumer.IdUser
            this.selectedCustomer = response.data.data
            this.loadExistingCustomerInformation(userCostumer)
          }
        } catch (error) {
          this.showToast('Error al cargar el cliente', getError(error), 'danger')
        }
      } else {
        this.selectedCustomer = null
        this.resetValues()
      }
    },
    formCustomerType() {
      if (this.formCustomerType === 'Físico') {
        this.formCustomerBusinessName = ''
      }
    },
  },
  created() {
    if (this.customerData) {
      this.loadExistingCustomerInformation(this.customerData, true)
    }

    this.formCustomerType = 'Físico'

    localize('es')
  },
  methods: {
    ...mapActions({
      setIdQuote: 'quotes/setIdQuote',
      loadCustomer: 'quotes/loadCustomer',
      setIdCustomer: 'quotes/setIdCustomer',
      setCustomerType: 'quotes/setCustomerType',
      setBusinessName: 'quotes/setBusinessName',
      setCustomerPhone: 'quotes/setCustomerPhone',
      setCustomerEmail: 'quotes/setCustomerEmail',
      setSelectedCustomer: 'quotes/setSelectedCustomer',
      setCustomerLastName: 'quotes/setCustomerLastName',
      setCustomerFirstName: 'quotes/setCustomerFirstName',
      updateCustomerInformation: 'quotes/updateCustomerInformation',
    }),
    openModal() {
      this.$refs.customerDataModal.show()

      this.formCustomerType = this.customerType
      this.formCustomerPhone = this.customerPhone
      this.formCustomerEmail = this.customerEmail
      this.formCustomerLastName = this.customerLastName
      this.formCustomerFirstName = this.customerFirstName
      this.formCustomerBusinessName = this.customerBusinessName
    },
    handleReplaceData(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      const success = await this.$refs.customerDataForm.validate()

      if (success) {
        this.customerType = this.formCustomerType
        this.customerPhone = this.formCustomerPhone
        this.customerEmail = this.formCustomerEmail
        this.customerLastName = this.formCustomerLastName
        this.customerFirstName = this.formCustomerFirstName
        this.customerBusinessName = this.formCustomerBusinessName

        this.formSelectedCustomer = `${this.formCustomerFirstName} ${this.formCustomerLastName}`

        const formData = new FormData()

        formData.append('id', this.idCustomer || this.userCostumer.IdUser)
        // formData.append('saveInfo', this.updateData ? 1 : 0)

        if (this.idQuote) {
          formData.append('IdQuote', this.idQuote)
        }

        formData.append('type', this.customerType)
        formData.append('phone', this.customerPhone)
        formData.append('email', this.customerEmail)
        formData.append('name', this.customerFirstName)
        formData.append('lastName', this.customerLastName)
        formData.append('businessName', this.customerBusinessName !== null ? this.customerBusinessName : '')

        // eslint-disable-next-line no-restricted-syntax
        for (const pair of formData.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`)
        }

        try {
          const response = await this.updateCustomerInformation(formData)

          if (!this.idQuote) {
            this.idQuote = response.data.data
          }

          this.showSwalFire('¡Los datos del cliente han sido actualizados correctamente!')
        } catch (error) {
          this.$swal.close()
          this.showToast('Error de validación', getError(error), 'danger')
        }

        this.$nextTick(() => {
          this.$refs.customerDataModal.toggle('#toggle-btn')
        })

        this.resetValues()
      }
    },
    hideModal() {
      this.$refs.customerDataModal.hide()
      this.resetValues()
    },
    loadExistingCustomerInformation(customerData, loadUser = false) {
      if (loadUser) {
        this.formSelectedCustomer = {
          name: `${customerData.Name} ${customerData.LastName}`,
        }

        this.idCustomer = customerData.IdUser
      }

      this.customerType = customerData.Type

      if (this.customerType === 'Físico') {
        this.customerBusinessName = ''
      } else {
        this.customerBusinessName = customerData.BusinessName
      }

      this.customerPhone = customerData.Phone
      this.customerEmail = customerData.email
      this.customerFirstName = customerData.Name
      this.customerLastName = customerData.LastName
    },
    resetValues() {
      this.formCustomerType = ''
      this.formCustomerBusinessName = ''
      this.formCustomerPhone = ''
      this.formCustomerEmail = ''
      this.formCustomerLastName = ''
      this.formCustomerFirstName = ''
      // this.updateData = false
    },
    showSwalFire(message) {
      this.$swal.fire({
        title: 'Guardado',
        text: message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        allowOutsideClick: false,
      })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
