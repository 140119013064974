<template>
  <div>

    <!-- #region::Circuit filters -->
    <CircuitListFilters
      :show-title="false"
      :circuit-number="totalCircuits + 1"
      :show-download-file="showDownloadFile"
      :can-apply-global-filters="canApplyGlobalFilters"
      @on-filter="handleFilter"
    />
    <!-- #endregion::Circuit filters -->

    <!-- #region::Skeleton -->
    <template v-if="isLoadingCircuits">
      <b-skeleton
        class="mt-1"
        animation="wave"
        width="100%"
        height="430px"
      />
    </template>
    <!-- #region::Skeleton -->

    <template v-else>
      <b-card
        class="mt-1 border border-dark shadow-none"
        no-body
      >
        <b-card-text>

          <!-- #region::Circuits list -->
          <b-table
            v-if="availableCircuits"
            :items="formartedItems"
            :fields="columns"
            responsive="sm"
            class="my-0"
          >

            <!-- #region::A virtual column for checbox -->
            <template #cell(selectCircuit)="{item}">
              <b-form-checkbox
                v-model="item.selected"
                @change="onCircuitChecked(item)"
              />
            </template>
            <!-- #endregion::A virtual column for checbox -->

            <!-- #region::A virtual column for circuit id -->
            <template #cell(IdCircuit)="{ item }">
              <div class="d-flex">
                <span class="text-nowrap">{{ item.IdCircuit }}</span>
                <div
                  v-if="item.Transfer === 'Si'"
                  class="sm-banner-alert alert-pink"
                  role="alert"
                >
                  Traspaso
                </div>
              </div>
            </template>
            <!-- #endregion::A virtual column for circuit id -->

            <!-- #region::A virtual column for prices -->
            <template #cell(prices)="{ item }">
              {{ item.PriceMin | currency }} - {{ item.PriceMax | currency }}
            </template>
            <!-- #endregion::A virtual column for prices -->

            <!-- #region::A virtual column for offer price -->
            <template #cell(OfferPrice)="{ item }">
              <span class="text-nowrap">{{ item.OfferPrice | currency }}</span>
            </template>
            <!-- #endregion::A virtual column for offer price -->

            <!-- #region::A virtual column for toggle button -->
            <template
              v-if="$ability.can('read', 'Circuit')"
              #cell(details)="{ detailsShowing, item }"
            >
              <b-button
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                @click="toggleDetails(item)"
              >
                <feather-icon :icon="detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
              </b-button>
            </template>
            <!-- #endregion::A virtual column for toggle button -->

            <!-- #region::Circuit details -->
            <template
              v-if="$ability.can('read', 'Circuit')"
              v-slot:row-details="{ item }"
            >
              <b-card
                no-body
                class="mt-1"
              >
                <CircuitsListDetails
                  v-if="item.products"
                  :id="item.IdCircuit"
                  :products="item.products"
                  :show-pieces-list="true"
                />
              </b-card>
            </template>
          <!-- #endregion::Circuit details -->

          </b-table>
          <!-- #endregion::Circuits list -->

          <!-- #region::Alert when no circuitos are available -->
          <b-alert
            v-if="!availableCircuits && !isLoadingCircuits"
            variant="warning"
            class="mb-0"
            show
          >
            <div class="alert-body">
              <span>No se encontraron circuitos.</span>
            </div>
          </b-alert>
          <!-- #endregion::Alert when no circuitos are available -->

        </b-card-text>
      </b-card>
    </template>

    <!-- #region begin::Pagination & items per list -->
    <BasicPaginator
      v-if="availableCircuits"
      class="mt-2"
      :total-rows="totalCircuits"
      :callback="handleChangePagination"
      :can-apply-global-pagination="canApplyGlobalPagination"
    />
    <!-- #endregion end::Pagination & items per list -->
  </div>
</template>

<script>
// #region Imports
import { mapActions, mapGetters } from 'vuex'

import {
  VBTooltip, BTable, BButton, BCard, BCardText, BSkeleton, BAlert, BFormCheckbox,
} from 'bootstrap-vue'

import 'vue-good-table/dist/vue-good-table.css'
// import Spinbutton from '@/components/forms/Spinbutton.vue'
import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CircuitListFilters from '@/modules/production/circuits/components/CircuitListFilters.vue'
// #endregion

export default {
  name: 'CircuitsList',
  components: {
    BCard,
    BTable,
    BAlert,
    BButton,
    BCardText,
    BSkeleton,
    // Spinbutton,
    BFormCheckbox,
    BasicPaginator,
    CircuitListFilters,
    CircuitsListDetails: () => import('@/modules/production/circuits/components/CircuitsListDetails.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    /**
     * Indica si se pueden descargar archivos
     */
    showDownloadFile: {
      type: Boolean,
      default: false,
    },
    /**
     * Indica si se puede mostrar el botón para mostrar los
     * detalles del circuito
     */
    showToggleButton: {
      type: Boolean,
      default: true,
    },
    /**
     * Indica si se pueden aplicar los filtros globales
     */
    canApplyGlobalFilters: {
      type: Boolean,
      default: true,
    },
    /**
     * Indica si se puede aplicar la paginación global
     */
    canApplyGlobalPagination: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchTerm: '',
      currentPage: 1,
      totalCircuits: 0,
      perPage: 10,
      columns: [
        {
          key: 'selectCircuit',
          label: '',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'IdCircuit',
          label: 'No. Circuito',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Name',
          label: 'Nombre',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'prices',
          label: 'Precio',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'OfferPrice',
          label: 'Precio oferta',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Qty',
          label: 'No. de productos',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        // {
        //   key: 'count',
        //   label: 'Cantidad',
        //   thClass: 'bg-light',
        //   thStyle: 'text-transform: none;',
        // },
        {
          key: 'Location',
          label: 'Ubicación',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'details',
          label: '',
          thClass: this.$ability.can('read', 'Circuit') ? 'bg-light' : 'd-none',
          tdClass: this.showToggleButton && this.$ability.can('read', 'Circuit') ? '' : 'd-none',
        },
      ],
      circuits: [],
      selected: [],
      allOpenRows: [],
      currentItems: [],
      isLoadingCircuits: false,
    }
  },
  computed: {
    ...mapGetters({
      getQuotePieces: 'quotes/getQuotePieces',
      getQuoteCircuits: 'quotes/getQuoteCircuits',
      getQuoteSelectedCircuits: 'quotes/getQuoteSelectedCircuits',
    }),
    quoteCircuits: {
      get() { return this.getQuoteCircuits },
    },
    quotePieces: {
      get() { return this.getQuotePieces },
    },
    selectedCircuits: {
      get() { return this.getQuoteSelectedCircuits },
    },
    formartedItems() {
      if (!this.circuits) return []
      return this.circuits.map(item => {
        this.offRow(item)
        return item
      })
    },
    availableCircuits() {
      return this.totalCircuits > 0 && this.formartedItems.length > 0
    },
  },
  async created() {
    await this.loadCircuitsList()
  },
  methods: {
    ...mapActions({
      loadCircuits: 'circuits/loadCircuits',
      addSelectedCircuit: 'quotes/addSelectedCircuit',
      deleteSelectedCircuit: 'quotes/deleteSelectedCircuit',
      updateSelectedCircuit: 'quotes/updateSelectedCircuit',
    }),
    onRowSelected(items) {
      this.selected = items
    },
    toggleDetails(row) {
      // eslint-disable-next-line no-underscore-dangle
      if (row._showDetails) {
        this.offRow(row)
      } else {
        for (let index = 0; index < this.currentItems.length; index += 1) {
          this.offRow(this.$set(this.currentItems[index]))
        }

        this.currentItems.forEach(item => {
          this.offRow(item)
        })

        this.$nextTick(() => {
          this.onRow(row)
        })
      }
    },
    offRow(row) {
      this.$set(row, '_showDetails', false)
      this.$set(row, '_cellVariants', {
        selectCircuit: '', IdCircuit: '', Name: '', prices: '', OfferPrice: '', Qty: '', Location: '', details: '',
      })
    },
    onRow(row) {
      this.$set(row, '_showDetails', true)
      this.$set(row, '_cellVariants', {
        selectCircuit: 'primary', IdCircuit: 'primary', Name: 'primary', prices: 'primary', OfferPrice: 'primary', Qty: 'primary', Location: 'primary', details: 'primary',
      })
    },
    async handleFilter(filter) {
      this.searchTerm = filter.value
      this.loadCircuitsList()
    },
    async handleChangePagination(currentPage, perPage) {
      this.currentPage = currentPage
      this.perPage = perPage
      this.loadCircuitsList()
    },
    async loadCircuitsList() {
      this.$swal({
        title: 'Cargando...',
        allowOutsideClick: false,
      })
      this.$swal.showLoading()
      this.isLoadingCircuits = true

      const response = await this.loadCircuits(this.loadParams())
      this.circuits = response.data.circuits.circuits.data
      this.totalCircuits = response.data.circuits.circuits.total
      this.currentPage = response.data.circuits.circuits.current_page

      this.circuits.forEach(circuit => {
        const quotecircuit = this.quoteCircuits.find(c => c.IdCircuit === circuit.IdCircuit)
        const quoteSelectedcircuit = this.selectedCircuits.find(c => c.IdCircuit === circuit.IdCircuit)

        this.$set(circuit, 'selected', (quotecircuit !== undefined) || (quoteSelectedcircuit !== undefined))
        // this.$set(circuit, 'count', quotecircuit !== undefined ? quotecircuit.count : 1)
        if (quotecircuit && !quoteSelectedcircuit) {
          this.addSelectedCircuit(circuit)
        }
      })

      this.isLoadingCircuits = false
      this.$swal.close()
    },
    onCircuitChecked(circuit) {
      // #region::Validate prices products to be defined
      let pricesDefined = false
      let unavailablePieces = false

      circuit.products.forEach(product => {
        if (product.PricesDefined) {
          pricesDefined = true
        }

        const isUnavailable = p => p.piece.Availability === 'No disponible'
        const unavailable = product.detailCircuit.some(isUnavailable)

        if (unavailable) {
          unavailablePieces = true
        }
      })

      if (unavailablePieces) {
        this.showToast(
          'Error de validación',
          'Existen piezas no disponibles en uno o más productos del circuito.',
          'warning',
        )

        const baseCircuit = circuit
        baseCircuit.selected = false

        return
      }

      if (pricesDefined) {
        this.showToast(
          'Error de validación',
          'Existen productos con precios por definir en el circuito.',
          'warning',
        )

        const baseCircuit = circuit
        baseCircuit.selected = false

        return
      }
      // #endregion

      // #region::Validate location against added pieces
      if (this.quotePieces.length > 0) {
        if (this.quotePieces[0].Location !== circuit.Location) {
          const baseCircuit = circuit
          baseCircuit.selected = false

          this.showToast(
            'Error de validación',
            'Existen piezas con diferente ubicación agregadas en la cotización.',
            'warning',
          )

          return
        }
      }
      // #endregion

      // #region::Prevent deleting circuits from list
      const quoteCircuits = this.quoteCircuits.filter(c => c.IdCircuit === circuit.IdCircuit)

      if (quoteCircuits.length > 0 && !circuit.selected) {
        const baseCircuit = circuit
        baseCircuit.selected = true

        this.showToast(
          'Error de validación',
          'El circuito no puede ser deseleccionado, es necesario eliminar los circuitos agregados a la cotización.',
          'warning',
        )

        return
      }
      // #endregion

      // #region::Validate if circuit available
      if (circuit.Transfer === 'Si') {
        const baseCircuit = circuit
        baseCircuit.selected = false

        this.showToast(
          'Error de validación',
          'El circuito no puede ser deseleccionado ya que se encuentra en una orden de traspaso.',
          'warning',
        )

        return
      }
      // #endregion

      // #region::Validate location against circuits added to quote
      const isSameLocation = selectedCircuit => selectedCircuit.Location === circuit.Location
      const sameCircuitLocation = this.selectedCircuits.every(isSameLocation)

      if (sameCircuitLocation) {
        const selectedCircuit = { ...circuit }
        selectedCircuit.selected = !selectedCircuit.selected
        if (!selectedCircuit.selected) {
          this.addSelectedCircuit(selectedCircuit)
        } else {
          this.deleteSelectedCircuit(selectedCircuit)
        }
      } else {
        const baseCircuit = circuit
        baseCircuit.selected = false
        this.showToast(
          'Ubición incorrecta',
          'El circuito seleccionado no pertenece a la misma ubicación.',
          'warning',
        )
      }
      // #endregion
    },

    /**
    * Parámetros de carga
    *
    * @summary Devuelve los parámetros de carga de la lista
    */
    loadParams() {
      return {
        perPage: this.perPage,
        resource: 'circuits',
        searchTerm: this.searchTerm,
        currentPage: this.currentPage,
        qtyActive: 1,
      }
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
