import { render, staticRenderFns } from "./QuoteProductsList.vue?vue&type=template&id=3bcdf071&"
import script from "./QuoteProductsList.vue?vue&type=script&lang=js&"
export * from "./QuoteProductsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports